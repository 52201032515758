@charset "utf-8";

@import './variables';
@import './fonts';

*,
*::after,
*::before {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
}
