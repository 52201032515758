$body-overflow-y: auto;
$family-primary: 'TT Commons';
$hr-background-color: transparent;
$body-color: #212529;
$tablet: 768px;
@import "~bulma/sass/utilities/_all";
@import "~bulma/sass/base/animations";
@import "~bulma/sass/base/generic";
@import "~bulma/sass/helpers/_all";
@import "~bulma/sass/grid/columns";
@import "~bulma/sass/elements/container";
@import "~bulma/sass/elements/content";
@import "~bulma/sass/elements/title";
@import "~bulma/sass/elements/other";
@import "~bulma/sass/components/level";
@import "~bulma/sass/components/modal";

/* start section with different fixes for styles related to conflicts between Bulma and our custom css */

html {
  background: none; // this fixes conflict about background between Bulma css and our custom css (mainly on Login page)
}

hr {
  margin: 1rem 0;
}

.modal-backdrop {
  z-index: 6 !important;
}
/* end section with different fixes for styles related to conflicts between Bulma and our custom css */
