@font-face {
  font-family: 'TT Commons';
  src:
    url('../fonts/TTCommons-Thin.woff2') format('woff2'),
    url('../fonts/TTCommons-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'TT Commons';
  src:
    url('../fonts/TTCommons-ThinItalic.woff2') format('woff2'),
    url('../fonts/TTCommons-ThinItalic.woff') format('woff');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'TT Commons';
  src:
    url('../fonts/TTCommons-ExtraLight.woff2') format('woff2'),
    url('../fonts/TTCommons-ExtraLight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'TT Commons';
  src:
    url('../fonts/TTCommons-ExtraLightItalic.woff2') format('woff2'),
    url('../fonts/TTCommons-ExtraLightItalic.woff') format('woff');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'TT Commons';
  src:
    url('../fonts/TTCommons-Light.woff2') format('woff2'),
    url('../fonts/TTCommons-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'TT Commons';
  src:
    url('../fonts/TTCommons-LightItalic.woff2') format('woff2'),
    url('../fonts/TTCommons-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'TT Commons';
  src:
    url('../fonts/TTCommons-Regular.woff2') format('woff2'),
    url('../fonts/TTCommons-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'TT Commons';
  src:
    url('../fonts/TTCommons-Italic.woff2') format('woff2'),
    url('../fonts/TTCommons-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'TT Commons';
  src:
    url('../fonts/TTCommons-Medium.woff2') format('woff2'),
    url('../fonts/TTCommons-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'TT Commons';
  src:
    url('../fonts/TTCommons-MediumItalic.woff2') format('woff2'),
    url('../fonts/TTCommons-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'TT Commons';
  src:
    url('../fonts/TTCommons-DemiBold.woff2') format('woff2'),
    url('../fonts/TTCommons-DemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'TT Commons';
  src:
    url('../fonts/TTCommons-DemiBoldItalic.woff2') format('woff2'),
    url('../fonts/TTCommons-DemiBoldItalic.woff') format('woff');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'TT Commons';
  src:
    url('../fonts/TTCommons-Bold.woff2') format('woff2'),
    url('../fonts/TTCommons-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'TT Commons';
  src:
    url('../fonts/TTCommons-BoldItalic.woff2') format('woff2'),
    url('../fonts/TTCommons-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'TT Commons';
  src:
    url('../fonts/TTCommons-ExtraBold.woff2') format('woff2'),
    url('../fonts/TTCommons-ExtraBold.woff') format('woff');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'TT Commons';
  src:
    url('../fonts/TTCommons-ExtraBoldItalic.woff2') format('woff2'),
    url('../fonts/TTCommons-ExtraBoldItalic.woff') format('woff');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'TT Commons';
  src:
    url('../fonts/TTCommons-Black.woff2') format('woff2'),
    url('../fonts/TTCommons-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'TT Commons';
  src:
    url('../fonts/TTCommons-BlackItalic.woff2') format('woff2'),
    url('../fonts/TTCommons-BlackItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
}

* {
  font-family: 'TT Commons';
}

@font-face {
  font-family: 'Beirut';
  src:
    url('https://fonts.accoladeapis.com/beirut/regular.woff2');
  font-style: normal;
}

@font-face {
  font-family: 'Beirut';
  src:
    url('https://fonts.accoladeapis.com/beirut/lightitalic.woff2');
  font-style: italic;
}

@font-face {
  font-family: 'DM Sans';
  src:
    url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');
  font-style: normal;
}